import { tryLogin } from "./auth.services";
import { errorInitialValue } from "./auth.slice";

const authAsyncActions = (builder) => {
  builder.addCase(tryLogin.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(tryLogin.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.token = action.payload.data.access_token;
  });

  builder.addCase(tryLogin.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });
};

export default authAsyncActions;
