import { lazyImport } from '../utils/lazyImport';

import { routePaths } from '../config';

const { HomeRoutes } = lazyImport(
  () => import('../features/misc'),
  'HomeRoutes'
);

export const publicRoutes = [
  {
    path: '*',
    element: <HomeRoutes />
  }
];
