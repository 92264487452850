import { createAsyncThunk } from "@reduxjs/toolkit";

import to from "await-to-js";

import { login } from "@/features/auth/api";

import handlingError from "@/utils/handlingError";

export const tryLogin = createAsyncThunk(
  "auth/tryLogin",
  async ({ username, password }, { rejectWithValue }) => {
    const [error, data] = await to(login(username, password));

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);
