import { current } from "@reduxjs/toolkit";

export const addItemToCart = (cartItems, cartItemToAdd) => {
  // TODO : check if item is identical before add

  return [
    ...cartItems,
    { ...cartItemToAdd, quantity: 1, order: cartItems.length },
  ];
};

export const removeItemToCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    (cartItem) => `${cartItem.itemId}` === `${cartItemToRemove.itemId}`
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) => `${cartItem.itemId}` !== `${cartItemToRemove.itemId}`
    );
  }

  return cartItems.map((cartItem) =>
    `${cartItem.itemId}` === `${cartItemToRemove.itemId}`
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const changeItemQuantityCart = (cartItems, { id, quantity, order }) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.itemId === id && cartItem.order === order
  );

  if (existingCartItem && quantity === 0) return cartItems.filter((cartItem) => cartItem.order !== order);
  else if (existingCartItem && quantity > 0) {
    return cartItems.map((cartItem) =>
      `${cartItem.itemId}` === id && cartItem.order === order
        ? { ...cartItem, quantity }
        : cartItem
    );
  }
};
