import { createSelector } from "reselect";
import jwt_decode from "jwt-decode";

export const selectAuth = (state) => state.auth;

export const selectAuthErrors = createSelector(
  [selectAuth],
  (auth) => auth.errors
);

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  (auth) => !!auth.token
);

export const selectAuthToken = createSelector(
  [selectAuth],
  (auth) => auth.token
);

export const selectAuthUser = createSelector([selectAuth], (auth) =>
  auth.token ? jwt_decode(auth.token).clientType : null
);
