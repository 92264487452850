import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import cartSliceReducer from "./cart/cart.slice";
import authSlice, { authSliceActions } from "./auth/auth.slice";

export const persistConfig = {
  key: "lyveatapp",
  storage,
  whitelist: ["cart", "auth"],
  blacklist: [],
};

const topReducer = combineReducers({
  cart: cartSliceReducer,
  auth: authSlice,
});

const rootReducer = (state, action) => {
  //when RESET action is dispatched it will reset redux state

  if (action.type === authSliceActions.logout.type) {
    state = undefined;
  }

  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
