import { createSlice } from "@reduxjs/toolkit";

import * as cartAction from "./cart.actions";

const initialState = {
  cartItems: [],
  deals: [],
  restaurantId: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ...cartAction,
  },
});

export const cartSliceActions = cartSlice.actions;

export default cartSlice.reducer;
