import Axios from "axios";

export const login = (username, password) => {
  const token = `${username}:${password}`;

  return Axios.post(
    "https://test-api-lyveat.com/v1/oauth/client/login",
    {},
    {
      auth: {
        username,
        password,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
