import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

import { AppProvider } from "./providers/app.provider";

import GlobalStyle from "./theme";

import AppRoutes from "./routes";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <AppProvider>
      <GlobalStyle />
      <Helmet>
        <title>Lyveat -Developer - platforms</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Poppins:wght@200;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ToastContainer />
      <AppRoutes />
    </AppProvider>
  );
};

export default App;
