import { Navigate, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { setAuthToken } from "@/utils/setAuthToken";

import { publicRoutes } from "./public.routes";
import { useEffect, useState } from "react";
import {
  selectAuthToken,
  selectIsAuthenticated,
} from "@/store/reducers/auth/auth.selector";
import { routePaths } from "@/config";

const AppRoutes = ({ isAuthenticated, userToken }) => {
  const [isConnected, setIsConnected] = useState(isAuthenticated);

  useEffect(() => {
    setIsConnected(isAuthenticated);
  }, [isAuthenticated]);

  setAuthToken(userToken);

  const commonRoutes = [
    {
      path: "*",
      element: <Navigate to={`${routePaths.root}`} />,
    },
  ];

  //const routes = isConnected ? protectedRoutes : publicRoutes;

  const element = useRoutes([...publicRoutes]);

  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
  userToken: selectAuthToken,
});

export default connect(mapStateToProps)(AppRoutes);
