import {
  addItemToCart,
  removeItemToCart,
  changeItemQuantityCart,
} from "./cart.utils";

export const addItem = (state, action) => {
  if (state.restaurantId === null) {
    state.restaurantId = action.payload.restaurantId;
  } else if (state.restaurantId !== action.payload.restaurantId) {
    state.restaurantId = action.payload.restaurantId;
    state.cartItems = [];
    state.deals = [];
  }

  state.cartItems = addItemToCart(state.cartItems, action.payload.item);
};

export const clearItemFromCart = (state, action) => {
  state.cartItems = state.cartItems.filter(
    (cartItem) =>
      `${cartItem._id}-${cartItem.details.displayOrder}` !==
      `${action.payload._id}-${action.payload.details.displayOrder}`
  );

  if (state.cartItems.length === 0) {
    state.restaurantId = null;
  }
};

export const removeItem = (state, action) => {
  state.cartItems = removeItemToCart(state.cartItems, action.payload);

  if (state.cartItems.length === 0) {
    state.restaurantId = null;
  }
};

export const clearCart = (state, action) => {
  state.cartItems = [];
  state.deals = [];
  state.restaurantId = null;
};

export const changeItemQuantity = (state, action) => {
  state.cartItems = changeItemQuantityCart(state.cartItems, action.payload);

  const cartItemIds = state.cartItems.map((cartItem) => cartItem.itemId);

  const totalPrice = state.cartItems.reduce((accumulatedQuantity, cartItem) => {
    return accumulatedQuantity + cartItem.quantity * cartItem.totalPrice;
  }, 0);

  if (!state.deals) state.deals = []

  if (action.payload.quantity === 0) {
    state.deals.forEach((deal) => {
      switch (deal.type) {
        case "free_item_minbasket":
          if (
            totalPrice < deal.minAmount * 100 &&
            deal.basketItems.some((basketItem) =>
              cartItemIds.includes(basketItem._id)
            )
          ) {
            const findDeal = state.deals.find(
              (sdeal) => sdeal._id === deal._id
            );

            if (findDeal) {
              state.deals = state.deals.filter(
                (sdeal) => sdeal._id !== deal._id
              );
            }

            let cartItems = [...state.cartItems];

            const temp = state.cartItems.map((cartItem) => {
              if (
                deal.basketItems.some((basketItem) =>
                  cartItemIds.includes(basketItem._id)
                )
              ) {
                const item = { ...cartItem };
                delete item.instanceId;
                return item;
              }

              return cartItem;
            });

            state.cartItems = [...temp];
          }

          break;

        case "buy_x_get_y":
          if (
            !(
              deal.grantingItems.some((grantingItem) =>
                cartItemIds.includes(grantingItem._id)
              ) &&
              deal.grantedItems.some((grantedItem) =>
                cartItemIds.includes(grantedItem._id)
              )
            )
          ) {
            const findDeal = state.deals.find(
              (sdeal) => sdeal._id === deal._id
            );

            if (findDeal) {
              state.deals = state.deals.filter(
                (sdeal) => sdeal._id !== deal._id
              );
            }

            let cartItems = [...state.cartItems];

            const findItem = cartItems.find(
              (cartItem) =>
                deal.grantedItems.some(
                  (grantedItem) => grantedItem._id === cartItem.itemId
                ) && cartItem.instanceId !== undefined
            );

            if (findItem) {
              const temp = state.cartItems.map((cartItem) => {
                if (findItem.itemId === cartItem.itemId) {
                  const item = { ...cartItem };
                  delete item.instanceId;
                  return item;
                }

                return cartItem;
              });

              state.cartItems = [...temp];
            }
          }

          break;
      }
    });
  }

  state.deals.forEach((deal) => {
    const totalPrice = state.cartItems.reduce(
      (accumulatedQuantity, cartItem) => {
        return accumulatedQuantity + cartItem.quantity * cartItem.totalPrice;
      },
      0
    );

    switch (deal.type) {
      case "free_delivery":
        if (totalPrice < deal.minAmount * 100) {
          const findDeal = state.deals.find((sdeal) => sdeal._id === deal._id);

          if (findDeal) {
            state.deals = state.deals.filter((sdeal) => sdeal._id !== deal._id);
          }
        }
        break;
    }
  });

  if (state.cartItems.length === 0) {
    state.restaurantId = null;
  }
};

export const toggleDeal = (state, action) => {
  const findDeal = state.deals.find((deal) => deal._id === action.payload._id);
  if (findDeal) {
    state.deals = state.deals.filter((deal) => deal._id !== action.payload._id);

    const deal = action.payload;
    const cartItemIds = state.cartItems.map((cartItem) => cartItem.itemId);
    let cartItems = [...state.cartItems];

    switch (deal.type) {
      case "buy_x_get_y":
        const grantedItemsIds = deal.grantedItems.map(
          (grantedItem) => grantedItem._id
        );

        if (
          deal.grantingItems.some((grantingItem) =>
            cartItemIds.includes(grantingItem._id)
          )
        ) {
          const grantedCartItemIds = cartItemIds.filter((id) =>
            grantedItemsIds.includes(id)
          );

          const temp = state.cartItems.map((cartItem) => {
            if (grantedCartItemIds.includes(cartItem.itemId)) {
              const item = { ...cartItem };
              delete item.instanceId;
              return item;
            }

            return cartItem;
          });

          cartItems = [...temp];

          return;
        }

        break;

      case "free_item_minbasket":
        const basketItemsIds = deal.basketItems.map(
          (basketItem) => basketItem._id
        );

        if (
          deal.basketItems.some((basketItem) =>
            cartItemIds.includes(basketItem._id)
          )
        ) {
          const basketCartItemIds = cartItemIds.filter((id) =>
            basketItemsIds.includes(id)
          );

          const temp = state.cartItems.map((cartItem) => {
            if (basketCartItemIds.includes(cartItem.itemId)) {
              const item = { ...cartItem };
              delete item.instanceId;
              return item;
            }

            return cartItem;
          });

          cartItems = [...temp];
        }

        break;
    }

    state.cartItems = [...cartItems];
  } else {
    state.deals = [...state.deals, action.payload];
  }
};

export const removeDeal = (state, action) => {
  const findDeal = state.deals.find((deal) => deal._id === action.payload._id);

  if (findDeal) {
    state.deals = state.deals.filter((deal) => deal._id !== action.payload._id);

    const cartItemIds = state.cartItems.map((cartItem) => cartItem.itemId);
    const totalPrice = state.cartItems.reduce((accumulatedTotal, cartItem) => {
      if (cartItem.instanceId === undefined) {
        return accumulatedTotal + cartItem.quantity * cartItem.totalPrice;
      }

      return accumulatedTotal;
    }, 0);

    switch (findDeal.type) {
      case "free_item_minbasket":
        if (
          totalPrice < findDeal.minAmount * 100 &&
          findDeal.basketItems.some((basketItem) =>
            cartItemIds.includes(basketItem._id)
          )
        ) {
          const temp = state.cartItems.map((cartItem) => {
            if (
              findDeal.basketItems.some((basketItem) =>
                cartItemIds.includes(basketItem._id)
              )
            ) {
              const item = { ...cartItem };
              delete item.instanceId;
              return item;
            }

            return cartItem;
          });

          state.cartItems = [...temp];
        }

        break;

      // case "buy_x_get_y":
      //   if (
      //     !(
      //       findDeal.grantingItems.some((grantingItem) =>
      //         cartItemIds.includes(grantingItem._id)
      //       ) &&
      //       findDeal.grantedItems.some((grantedItem) =>
      //         cartItemIds.includes(grantedItem._id)
      //       )
      //     )
      //   ) {
      //     let cartItems = [...state.cartItems];

      //     const findItem = cartItems.find(
      //       (cartItem) =>
      //         findDeal.grantedItems.some(
      //           (grantedItem) => grantedItem._id === cartItem.itemId
      //         ) && cartItem.instanceId !== undefined
      //     );

      //     if (findItem) {
      //       const temp = state.cartItems.map((cartItem) => {
      //         if (findItem.itemId === cartItem.itemId) {
      //           const item = { ...cartItem };
      //           delete item.instanceId;
      //           return item;
      //         }

      //         return cartItem;
      //       });

      //       state.cartItems = [...temp];
      //     }
      //   }

      //   break;
    }
  }
};
