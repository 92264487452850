import { createSlice } from "@reduxjs/toolkit";

import authAsyncActions from "./auth.asyncAction";
import * as authAction from "./auth.actions";

export const errorInitialValue = {
  message: "",
  value: null,
};

const initialState = {
  token: null,
  loading: "idle",
  errors: {
    message: "",
    value: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ...authAction,
  },
  extraReducers(builder) {
    authAsyncActions(builder);
  },
});

export const authSliceActions = authSlice.actions;

export default authSlice.reducer;
