const convertThemeColorsToRootColors = (themeColorsObj) => {
  let colors = "";
  let currentKey = [];
  let deeping = 0;

  const nominalFunction = (obj) => {
    const keys = Object.keys(obj);

    let currentLevel = {};

    keys.forEach((key, idx) => {
      if (idx < 1) {
        currentKey.push(key);
      } else {
        if (currentKey.length > 1) currentKey.pop();
        currentKey.push(key);
      }

      currentLevel = { ...obj };

      if (typeof currentLevel[key] === "string") {
        let tempCurrentKey = [...currentKey];

        const prop = tempCurrentKey.join("-");
        const color = `--${prop}: ${currentLevel[key]}`;

        colors += `${color};`;
      } else if (typeof currentLevel[key] === "object") {
        deeping += 1;

        nominalFunction(currentLevel[key], key);
      }
    });

    deeping -= 1;
    currentKey = currentKey.slice(0, deeping);
  };

  nominalFunction(themeColorsObj);

  return colors;
};

export default convertThemeColorsToRootColors;
