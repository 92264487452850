import Axios from "axios";

import { store } from "@/store";
import { authSliceActions } from "@/store/reducers/auth/auth.slice";

import { setAuthToken } from "@/utils/setAuthToken";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // UNAUTORIZED REQUEST ==> LOGOUT AUTOMATICALLY THE CURRENT CONNEXION
      setAuthToken(null);
      store.dispatch(authSliceActions.logout());
    }

    return Promise.reject(error);
  }
);

export async function requestHttp({ method, url, data, params }) {
  return await axios({
    method: method,
    url: url,
    data: data,
    params,
  });
}
