export const colors = {
  text: {
    primary: '#666666'
  },
  ui: {
    primary: '#3c9',
    secondary: '#222222'
  }
};

export const family = {
  family: {
    primary: "'Open Sans', sans-serif",
    secondary: "'Poppins', sans-serif"
  }
};
