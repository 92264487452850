export const orderStatus = {
  pickup: [
    {
      label: "En attente de client",
      value: "waiting for client",
      color: "#ffb302",
      order: 20,
    },
    // {
    //   label: "En attente de paiement",
    //   value: "waiting for payment",
    //   color: "#B9E0FF",
    // },
  ],
  delivery: [
    {
      label: "En attente du livreur",
      value: "waiting for rider",
      color: "#2232F3",
      order: 20,
    },
    {
      label: "Livreur assigné",
      value: "driver assigned",
      color: "#6200EE",
      order: 30,
    },
    {
      label: "Collecté",
      value: "collected",
      color: "#3700B3",
      order: 40,
    },
  ],
  common: [
    {
      label: "En cours",
      value: "processing",
      color: "#2dccff",
      order: 1,
    },
    {
      label: "Accepté",
      value: "cooking",
      color: "#fce83a",
      order: 10,
    },
    {
      label: "Terminé",
      value: "completed",
      color: "#56f000",
      order: 50,
    },
    {
      label: "Annulé",
      value: "canceled",
      color: "#9ea7ad",
      order: 60,
    },
    {
      label: "Echec",
      value: "failed",
      color: "#ff3838",
      order: 70
    },
  ],
};
