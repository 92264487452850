function handlingError(error) {
  let errorToSend;

  if (!error.response) {
    errorToSend = {
      message:
        "Un problème est survenu. Vérifier votre connexion par précaution.",
      value: error,
    };
  } else if (error.code === "ERR_NETWORK") {
    errorToSend = {
      message: "Une erreur réseau s'est produite, vérifiez votre connexion",
      value: error.code,
    };
  } else {
    errorToSend = {
      message: error.response.data.errors[0].message,
      value: error.response.status,
    };
  }

  return errorToSend;
}

export default handlingError;
