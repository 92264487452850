import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";

import { isDev } from "@/utils/devTools";

import rootReducer from "./reducers/rootReducer";

const loggerMiddleware = createLogger({
  predicate: () => isDev(),
  collapsed: true,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
  devTools: true,
});

export const persistor = persistStore(store);
